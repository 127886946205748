import { DateRange } from '@styled-icons/material';
import { Icon, IconProps } from './Icon';

export function DateIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <DateRange />
    </Icon>
  );
}
